@import "../core/colours";

.top_tabs_wrapper {
  border-bottom: 1px solid $c4;
  height: auto;
  transition: height 150ms;
}

.top_tabs {
  display: flex;
  gap: 0.5rem;
  align-items: stretch;
  background-color: $c1;
  color: $c3;
  padding: 1rem 1rem;
  font-weight: 500;

  @media (max-width: 1024px) {
    display: none;
  }
}

.top_tab {
  font-family: Inter;
  font-size: 0.9rem;
  font-weight: 400;
  text-decoration: none;
  padding: 0.4rem 0.85rem;
  cursor: pointer;
  border-radius: 999px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #858C91;

  &.active {
    background-color: #2a5a7b;
    color: white;
  }

  &.selected {
    font-weight: bold;
    text-decoration: underline;
  }

  &:hover {
    background-color: #416e90;
    color: white;
  }
}

.top_tab.selected {
  text-decoration: none;
}

.sub_tabs {
  display: flex;
  gap: 0.5rem;
  padding: 0 0.75rem;

  @media (max-width: 1024px) {
    display: none;
  }
}

.sub_tab {
  display: block;
  padding: 0.25rem 0.5rem;
  color: #858C91;
  font-size: 0.9rem;

  &.active {
    font-weight: 500;
    border-bottom: 2px solid $c2;
  }
}