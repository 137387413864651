@import "colours";

.pulse-carousel {
  overflow: hidden;
}

.pulse-carousel-inner {
  white-space: nowrap;
  transition: transform 0.3s;
}

.pulse-carousel-item {
  display: inline-flex;
  justify-content: center;
  min-height: 700px;
}

.indicators {
  display: flex;
  justify-content: center;
  background-color: $mc-blue-01;
}

.indicators > button {
  margin: 5px;
}

.indicators > button.active {
  background-color: green;
  color: #fff;
}
