@import "colours";
@import "typography";
@import "variables";

.xero-login {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.xero-login-card {
  width: calc(25% - 30px);
  background-color: $mc-white-01;
  border: 2px solid white;
  border-radius: 10px;
  position: relative;
  transition: all ease-in-out $animation-duration;

  box-shadow: 0 9px 8px 3px rgba($mc-blue-01, 0.3);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.xero-logo {
  width: 250px;
  aspect-ratio: 1/1;
}

.xero-login-status {
  font-family: $header-font;
  text-align: center;
}

.xero-login-card,
.xero-login-status,
.xero-login-button-wrapper {
  margin: 15px;
}

.xero-page-wrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.xero-disconnect-button {
  padding: 0.5rem 0.75rem;
  color: $mc-white-01;
  background-color: $mc-blue-01;
  transition: all ease $animation-duration;
  border-radius: 0.5rem;
  border: transparent;
  margin-bottom: 1rem;
  font-family: $header-font;

  &:hover {
    background-color: $mc-dark-blue-hover;
  }
}

.error-dot,
.invoice-incomplete {
  color: $mc-red-01;
  cursor: pointer;
}

.success-dot {
  color: $mc-green-01;
  cursor: pointer;
}

.xero-tracking {
  margin: 0 14px;
  .category-header {
    font-weight: 550;
  }

  .xero-tracking-item-input {
    margin-right: 10px;
  }

  .item-wrapper,
  .xero-tracking-item,
  .xero-tracking-item-input {
    cursor: pointer;
  }

  .xero-tracking-item.disabled {
    color: rgba($mc-blue-01, 0.4);
  }

  .item-wrapper.disabled,
  .xero-tracking-item.disabled,
  .xero-tracking-item-input.disabled {
    cursor: not-allowed;
  }
}
