.expando_content {
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 150ms;

  &.expanded {
    grid-template-rows: 1fr;
  }

  @media (prefers-reduced-motion) {
    transition: none;
  }

  .expando_content_child {
    overflow: hidden;
  }
}
