.pulse-modal {
  .pulse-modal-body-content-md {
    .pulse-modal-body {
      min-height: 34vh;
    }
  }

}

.pulse-modal-content-xl > .pulse-modal-body {
  min-height: 345px !important;
}

.pulse-modal-content-xl {
  //max-height: 550px !important;
}

.pulse-modal-body {
  padding: 1rem;
}

.pulse-modal-body .mc-form-row {
 //background-color: #ebeef2;
}

.pulse-modal-body .col{
  padding: 0;
}

.pulse-modal-body .row {
  //background-color: #ebeef2;
}

.modal-background {
  margin-left: 1rem;
  margin-right: 1rem;
}

.pulse-modal-content-sm .modal-background {
  margin-left: 0em;
  margin-right: 0rem;
}

.pulse-modal-header {
  padding: 1.25rem 1rem  !important;
}

.pulse-modal-footer {
  padding: 1rem;
  //border-top: 1px solid #eee;
}

.pulse-modal-title {
  font-size: 1.3rem;
}

.pulse-modal-content-sm {
  max-height: 600px;
}