@import "colours";
@import "typography";

.pulse-tab {
  background-color: #FAFAFA;
  padding: 0px 12px;
  color: $mc-blue-01;
  border: 1px solid #FAFAFA;
  cursor: pointer;
  height: 36px;
  font-family: $header-font;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  font-weight: 500;
  font-size: 1rem;
}

.pulse-tab-content {
  padding: 0rem 1rem 1rem 1rem;
  background-color: #FAFAFA;
  min-height: 550px;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.pulse-tab-content-inner {
  background-color: #fff;
  padding-bottom: 1rem;
}

.pulse-tab-content-inner .col_override {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.pulse-tab.active {
  background-color: #fff;
  color: #212529;
  border: 1px solid #fff;
  font-weight: 500;
}

.pulse-nav-tabs {
  margin-top: 10px;
  padding: 1rem 1rem 0rem 1rem;
  background-color: #FAFAFA;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.pulse-add-tab-button {
  background: none;
  border: none;
}