@import "colours";
@import "typography";
@import "variables";

.read-only {
  .assignment-base {
    width: 15px;
    height: 15px;
    border-radius: 10px;
    float: left;
    margin: 4px 3px 0 0;
    border-color: transparent;
  }

  .assignment-base.free {
    background-image: linear-gradient(to right, rgb(200, 200, 200), rgb(230, 230, 230));
  }

  .assignment-base.waitingAccept {
    //background-image: linear-gradient(to right, $vibrant-yellow, $vibrant-yellow-sec);
    background-color: #E0A309
  }

  .assignment-base.accepted {
    //background-image: linear-gradient(to right, $vibrant-blue, $vibrant-blue-sec);
    background-color: #2E5AAC;
  }

  .assignment-base.attended {
    //background-image: linear-gradient(to right, $vibrant-green, $vibrant-green-sec);
    background-color: #2A8A41;
  }

  .assignment-base.unassigned {
    background-image: linear-gradient(to right, $vibrant-red, $vibrant-red-sec);
  }

  .staff-member {
    font-family: $body-font;
    color: $mc-blue-01;
    text-transform: capitalize;
    font-size: 1em;
    float: left;
  }
}

.admin {
  .assignment-base {
    width: 16px;
    height: 16px;
    border-radius: 999px;
    float: left;
    margin: 4px 3px 5px 0;
    //border: 1px solid #aaaaaa;
  }

  .assignment-base:hover {
    cursor: pointer;
    //border: 1px solid $mc-grey-02;
    width: 16px;
    height: 16px;
    margin: 4px 3px 5px 0;
    transition: all $animation-duration ease;
  }

  .assignment-base.free {
    background-image: linear-gradient(to right, rgb(200, 200, 200), rgb(230, 230, 230));
  }

  .assignment-base.waitingAccept {
    //background-image: linear-gradient(to right, $vibrant-yellow, $vibrant-yellow-sec);
    background-color: #E0A309
  }

  .assignment-base.accepted {
    //background-image: linear-gradient(to right, $vibrant-blue, $vibrant-blue-sec);
    background-color: #2E5AAC;
  }

  .assignment-base.attended {
    //background-image: linear-gradient(to right, $vibrant-green, $vibrant-green-sec);
    background-color: #2A8A41;
  }

  .staff-email,
  .staff-member {
    font-family: $body-font;
    color: $mc-blue-01;
    float: left;
  }

  .staff-member {
    text-transform: capitalize;
    font-size: 1em;
  }
  .staff-email {
    text-transform: none;
    font-size: .9em;
  }

  .assignment-wrapper{
    width: 100%;
  }
}

.add {
  background-color: transparent;
  width: 21px;
  height: 21px;
  border-radius: 0.3rem;
  float: left;
  margin: 4px 3px 5px 0;
  border: 1px solid #D9D9D9;
  position: relative;

  .cross-vertical {
    position: absolute;
    transform: translate(9px, 5px);
    width: 1px;
    height: 10px;
    //background-color: $mc-blue-01;
    background-color: #1A2A35;
  }

  .cross-horizontal {
    position: absolute;
    transform: translate(4px, 9px);
    text-align: center;
    width: 11px;
    height: 1px;
    //background-color: $mc-blue-01;
    background-color: #1A2A35;
  }
}

.add:hover {
  border-width: 1px;
  transition: all $animation-duration ease;

  .cross-vertical {
    position: absolute;
    transform: translate(9px, 4px);
    //background-color: $mc-blue-01;
    background-color: #1A2A35;
    transition: all $animation-duration ease;
  }

  .cross-horizontal {
    position: absolute;
    transform: translate(3px, 9px);
    text-align: center;
    //background-color: $mc-blue-01;
    background-color: #1A2A35;
    transition: all $animation-duration ease;
  }
}
