@import "colours";
@import "typography";
@import "variables";

.toggle-wrapper-lg {
  padding: 0 15px !important;
}

.toggle-wrapper-sm {
  width: 65px !important;
}

.toggle-control {
  position: relative;
  padding: 3px 0;
  display: flex;
  align-items: center;
}

input[type='checkbox'].dmcheck {
  width: 55px;
  height: 22px;
  border-radius: 15px;
  background-color: #DADCE0;
  position: relative;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  vertical-align: 2px;
  outline: none;

  &:checked + label {
    left: 34px;
    background: #1A2A35;
  }

  &:focus-visible {
    outline: solid 2px $mc-white-01;
  }

  & + label {
    border: 1px solid $mc-white-01;
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    transition: all 0.3s ease;
    cursor: pointer;
    position: absolute;
    top: 4px;
    left: 1px;
    background: #fff;
  }
}

input[type='checkbox'].dmcheck-sm {
  width: 50px;
  height: 22px;
  background-color: #DADCE0;
  border-radius: 1rem;
  position: relative;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  vertical-align: 2px;
  outline: none;

  &:checked + label {
    left: 29px;
    background: #1A2A35;
  }

  &:focus-visible {
    outline: solid 2px $mc-white-01;
  }

  & + label {
    border: 1px solid $mc-white-01;
    display: inline-block;
    width: 20px;
    height: 20px;
    transition: all 0.3s ease;
    cursor: pointer;
    position: absolute;
    top: 4px;
    left: 1px;
    background: $mc-blue-01;
    border-radius: 50%;
    background: #fff;
  }
}

input[type='checkbox'].dmcheck:disabled,
input[type='checkbox'].dmcheck-sm:disabled {
  &:checked + label {
    background: $mc-yellow-disabled;
  }

  & + label {
    background: $mc-dark-blue-disabled
  }
}
