@import "colours";

.mc-form-heading {
  color:#7b8185;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: space-between;
}

.mc-form-heading-controls {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.5rem;
  padding-right: 1.5rem;
}

.mc-form-heading h5 {
  font-size: 1.2rem;
  font-weight: 500;
  color: #282C2F;
}

.mc-form-row-optional,
.mc-form-row {
  padding: 15px 25px 15px 17px;
  //background-color: #ebeef2;
}

.calendar_form .mc-form-row {
  background-color: #fff;
}

.readonly-warning {
  //background-color: $mc-yellow-01;
  //color: $mc-blue-01;
  background-color: #ffc107;
  color:#212529;
}

.row-splitter {
  border-bottom: 1px solid $mc-blue-01;
  margin: 15px;
}

.row-modifier {
  .mc-form-row-optional,
  .mc-form-row {
    background-color: #fff;
  }
}

.row-modifier {
  .row {
    background-color: #fff !important;
    margin-left: 1rem;
  }
}
