@import "../core/colours";

.dash-container {
  background-color: $c8;
  flex-grow: 1;
  border-top: 1px solid $c4;
  display: flex;
  flex-direction: column;
}

.dash-widgets {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.dash-widget-subtitle {
  color: $c3;
  font-size: 1rem;
  margin-top: 0.25rem;
}

.dash-widget-chart-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem 0 1rem 0;
}

.dash-widget-dot {
    width: 1rem;
    height: 1rem;
    border-radius: 999px;
}

.dash-row-outer {
  --max-height: 4000px;
  flex-grow: 1;
  display: grid;
  grid-template-columns: 2fr minmax(500px, 1fr);

  @media screen and (max-width: 1200px) {
    grid-template-columns: 1fr;
  }
}

.dash-row-compliance-stats {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;

  @media screen and (max-width: 1800px) {
    grid-template-columns: 1fr;
  }
}

.dash-left-container {
  background-color: $c8;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.dash-right-container {
  background-color: $c1;
  overflow-y: scroll;
  max-height: var(--max-height);
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;

  @media screen and (max-width: 1200px) {
    overflow-y: auto;
    max-height: none;
  }
}

// Individual widget in the layout
div.dash-widget {
  background-color: $c1;
  padding: 1.5rem;
  border-radius: 1rem;

  .dash-widget-split-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  a.dash-widget-more {
    font-size: 1.1rem;
    font-weight: 500;
  }

  a.dash-widget-more:hover {
    text-decoration: underline !important;
  }

  h2 {
    font-size: 1.2rem;
    margin: 0;
    padding: 0;
  }

  a {
    color: $c2;
    text-decoration: none;
  }

  // Specific locations for each widget
  &.drug_stats {
    grid-area: drug_stats;
  }

  &.absences {
    grid-area: absences;
  }

  &.compliance {
    grid-area: compliance;
  }
}

.widget-inline-values {
  display: flex;
  gap: 2rem;
  margin-left: 4rem;

  @media screen and (max-width: 750px) {
    flex-direction: column;
    gap: 1rem;
  }

  .widget-inline-value {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    .widget-inline-value-name {

    }

    .widget-inline-value-value {
      font-weight: 500;
      margin-left: 0.25rem;
    }
  }
}

.widget-compliance-name {
  display: flex;
  gap: 0.75rem;
}

.widget-compliance-name:hover {
  text-decoration: underline;
}

.widget-compliance {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
  border-radius: 0.5rem;
  padding: 0.2rem 0.5rem;
  text-align: right;

  &.notapplicable {
    background-color: #f4f4f5;
    color: #858c91;

    a {
      color: #858c91;
    }
  }

  &.compliant {
    background-color: #edf9f0;
    color: #2a8a41;

    a {
      color: #2a8a41;
    }
  }

  &.notcompliant {
    background-color: #feefef;
    color: #c70a0a;

    a {
      color: #c70a0a;
    }
  }

  &.partialcompliant {
    background-color: #fffbf2;
    color: #876d17;

    a {
      color: #876d17;
    }
  }
}

.widget-top-stats {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  margin-top: 1.5rem;

  @media screen and (max-width: 555px) {
    grid-template-columns: 1fr;
  }

  .widget-top-stat {
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;

    &.notapplicable {
      background-color: #f4f4f5;
      color: #858c91;

      a {
        color: #858c91;
      }
    }

    &.compliant {
      background-color: #edf9f0;
      color: #2a8a41;

      a {
        color: #2a8a41;
      }
    }

    &.notcompliant {
      background-color: #feefef;
      color: #c70a0a;

      a {
        color: #c70a0a;
      }
    }

    &.partialcompliant {
      background-color: #fffbf2;
      color: #ebc35d;

      a {
        color: #ebc35d;
      }
    }

    .widget-top-stat-heading {
      display: flex;
      align-items: center;
      gap: 1rem;
      font-size: 2rem;

      span {
        color: $c1-content;
        font-weight: bold;
      }
    }

    .widget-top-stat-description {
      color: $c1-content;
      font-size: 1rem;
      font-weight: 500;
    }
  }
}

.widget-staff-interest {
  background-color: $c8;

  .widget-staff-interest-inner {
    background-color: $c1;
    padding: 1rem;
  }

  @media screen and (max-width: 1200px) {
    padding: 0 1rem 1rem 1rem;

    .widget-staff-interest-inner {
      border-radius: 1rem;
    }
  }

  h2 {
    font-size: 1.2rem;
    margin: 0;
    padding: 0;
  }

  a {
    color: $c2;
    text-decoration: none;
  }

  .widget-staff-interest-no-interest {
    padding: 1rem;
    font-size: 1.1rem;
    text-align: center;
  }

  .widget-staff-interest-list {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    margin-top: 1.5rem;
  }

  .widget-staff-interest-spacer {
    width: 90%;
    background-color: $c4;
    height: 1px;
    margin: 0 auto;
  }

  .widget-staff-interest-label {
    color: $c3;
  }

  .widget-staff-interest-item {
    display: grid;
    grid-template-columns: minmax(300px, 1fr) auto;
    gap: 1rem;

    @media screen and (max-width: 500px) {
      grid-template-columns: 1fr;
    }
  }

  .widget-staff-interest-item-details {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .widget-staff-interest-item-staffname {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .widget-staff-interest-item-rows {
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 0.5rem;
    row-gap: 0.25rem;
  }

  .widget-staff-interest-value {
    span:first-child {
      font-weight: 500;
    }

    a {
      font-weight: 500 !important;
      text-decoration: underline !important;
    }
  }

  .widget-staff-interest-item-controls {
    @media screen and (max-width: 500px) {
      display: flex;
      justify-content: flex-end;
    }

    .btn-base-lg {
      background-color: #1a2a35;
      color: white;
      border: none;

      &:hover {
        background-color: #354550;
      }
    }
  }
}

.dash-widget-basic-stats {
  margin-top: 1rem;
}

.basic-items-list {
  .basic-item-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 0;
  }

  .basic-item-divider {
    flex-grow: 1;
  }

  .basic-item-divider-line {
    height: 2px;
    background-color: $c4;
    margin: 0 2rem;
  }
}

.basic-item-dot-name-wrapper {
  display: flex;
  align-items: center;
  gap: 0.5rem;

  .basic-item-dot {
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 999px;
  }

  .basic-item-dot-name {
  }
}

.perc-items-list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .perc-items-list-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .perc-items-list-row-icon {
      width: 1.25rem;
      height: 1.25rem;
      border-radius: 999px;
    }

  .perc-items-list-row-name {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .perc-items-list-row-value {
    font-weight: 500;
  }

  .perc-items-list-row-desc {
    color: $c3;
    font-size: 0.875rem;
  }
}