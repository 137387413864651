@import "colours";
/*@font-face {
  font-family:'Magistral';
  src: url("../fonts/Magistral-Bold.ttf");

}
@font-face {
  font-family:'Montserrat';
  src: url('../fonts/Montserrat-Regular.ttf');
}*/

@font-face {
  font-family:'Inter';
  src: url('../fonts/Inter.ttf');
}

$header-font: Inter;
$body-font: Inter;

li, a {
  text-decoration: none !important;
}

.body-font,
p, a {
  font-family: $body-font;
}

h1, h2, h3, h4, h5, h6,
.header-font {
  font-family: $header-font;
}

.point-eight-em {
  font-size: 0.8em !important;
}
.point-nine-em {
  font-size: 0.9em !important;
}
.one-em {
  font-size: 1em !important;
}
.one-point-zero-five-em {
  font-size: 1.05em !important;
}
.allowed-text {
  color: $mc-blue-01;
  font-size: 0.8em !important;
}
.disallowed-text {
  color: $mc-red-01;
  font-size: 0.8em !important;
}
