@import "../../core/colours";

div.calendar-entry-stats {
  display: flex;
  gap: 1rem;
  margin: 1rem;

  @media only screen and (max-width: 640px) {
    flex-direction: column;
  }
}

div.entry-stat {
  padding: 1rem;
  border-radius: 1rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;

  .entry-stat-label {
    display: flex;
    align-items: center;
    gap: 1rem;
    font-size: 2.5rem;
    font-weight: bold;
  }

  .entry-stat-details {
    display: flex;
    justify-content: space-between;
  }

  .entry-stat-details-name {
    font-weight: 500;
  }

  .entry-stat-details-value {
    color: $c3;
  }

  &.allocated {
    background-color: #fffbf2;

    .entry-stat-icon {
      color: #ebc35d;
    }
  }

  &.accepted {
    background-color: #e6eeff;

    .entry-stat-icon {
      color: #216af9;
    }
  }

  &.briefing_accepted {
    background-color: #edf9f0;

    .entry-stat-icon {
      color: #2a8a41;

    }
  }

  &.monthly {
    background-color: #edf9f0;

    .entry-stat-icon {
      color: #2f8a46;
    }
  }

  &.quarterly {
    background-color: #e5f0fa;

    .entry-stat-icon {
      color: #006ACC;
    }
  }

  &.yearly {
    background-color: #f8f3e5;

    .entry-stat-icon {
      color: #BD8700;
    }
  }

}