@import "colours";

.calendar-group-card {
  width: 260px;
  background-color: #F4F5F7;
  min-height: 445px;
  height: auto;
  padding: 0.5rem;
  margin-left: 1rem;
}

.calendar-group-card .mc-form-row {
  padding: 1rem;
  background-color: #F4F5F7 !important;
}

.calendar-group-card .row-modifier .row {
  background-color: #F4F5F7 !important;
}

.pulse-modal-body .row-modifier .row {
  margin-left: 0rem;
}

.pulse-modal-body .actions .col {
  margin-left: 0rem !important;
  padding-left: 0rem !important;
}

.calendar-group-card .row-modifier .actions {
  margin-left: 0 !important;
}

.group-card-button {
  height: 100px;
  width: 100px;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 50%;
}
