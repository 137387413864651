@import "../core/colours";

.head-tabs {
  border-bottom: 1px solid $c4;
  display: flex;
  gap: 0.5rem;
  padding: 0 0.75rem;
  //margin: 0 0 1.5rem 0;
  margin:0rem;
}

a.head-tab {
  display: block;
  padding: 0.25rem 0.5rem;
  color: $c3;

  &.active {
    font-weight: 500;
    border-bottom: 2px solid $c2;
  }
}