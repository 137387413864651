@import "colours";
@import "variables";
@import "typography";

.button-toggle {
  font-family: Inter;
  border: transparent;
  background-color: #fff;
  color: #1a2a35;
  padding: 0.5rem 0.7rem;
  border: 1px solid #1a2a35;

  transition: all ease-in-out $animation-duration;

  &:first-child {
    border-bottom-left-radius: 0.5rem;
    border-top-left-radius: 0.5rem;
  }
  &:last-child {
    border-bottom-right-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }
}
.button-toggle.selected {
  border: $mc-blue-01;
  background-color: $mc-blue-01;
  color: #fff;
  font-weight: 500 !important;
}

.date-switcher-button {
  height: 42px;
  width: 42px;
  //background-color: $mc-yellow-01;
  background-color: #1a2a35;
  //color: $mc-blue-01;
  color: #fff;
  border: 1px solid $mc-yellow-01;
  font-weight: 400 !important;

  &:hover {
    transition: all ease $animation-duration;
    //background-color: $mc-yellow-hover;
    background-color: #405368;
    //border: 1px solid $mc-yellow-hover;
    cursor: pointer;
  }
}

.report-button {
  padding-right: 0 !important;

  &:nth-child(odd) {
    padding-left: 0 !important;
  }
}

.btn-base-xl,
.btn-base-lg,
.btn-base {
  color: $mc-white-01;
  text-align: center;
  font-family: $header-font;
  font-size: 1rem;
  text-decoration: none;
  border: 1px solid transparent;
  //display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.75rem;
  margin-bottom: 0.5rem;
  font-weight: 500 !important;
  transition: background-color $animation-duration ease;
  -webkit-transition: background-color $animation-duration ease;
}

.btn-base-xl {
  font-size: 1rem;
}

.btn-base-lg {
  font-size: 1rem;
  margin-right: 0.5rem;
  //margin-left: 0.5rem;
}

.btn-base {
  font-size: 1rem;
}

.btn-base-xl.rounded-corner {
  border-radius: 0.5rem;
}

.btn-base-lg.rounded-corner {
  border-radius: 0.5rem;
}
.btn-base.rounded-corner {
  border-radius: 12.5px;
}

.btn-base-xl.scaled-font,
.btn-base-lg.scaled-font,
.btn-base.scaled-font {
  font-size: clamp(0.6em, unquote("0.3vw + 0.45rem"), 0.8em) !important;
}

.btn-base.green-btn,
.btn-base.light-blue-btn,
.btn-base.orange-btn,
.btn-base.red-btn,
.btn-base.yellow-btn,
.btn-base.dark-blue-btn,
.btn-base-lg.green-btn-lg,
.btn-base-lg.light-blue-btn-lg,
.btn-base-lg.orange-btn-lg,
.btn-base-lg.red-btn-lg,
.btn-base-lg.yellow-btn-lg,
.btn-base-lg.dark-blue-btn-lg,
.btn-base-xl.green-btn-xl,
.btn-base-xl.light-blue-btn-xl,
.btn-base-xl.orange-btn-xl,
.btn-base-xl.red-btn-xl,
.btn-base-xl.yellow-btn-xl,
.btn-base-xl.dark-blue-btn-xl {
  width: auto;
  &:hover {
    cursor: pointer;
  }
  &:disabled {
    cursor: default;
    transition: all $animation-duration ease;
    -webkit-transition: all $animation-duration ease;
  }
}

.btn-base.green-btn,
.btn-base-lg.green-btn-lg,
.btn-base-xl.green-btn-xl {
  background-color: $mc-green-01;
  border: 1px solid $mc-green-01;

  &:hover {
    background-color: $mc-green-hover;
    border: 1px solid $mc-green-hover;
  }

  &:disabled {
    background-color: $mc-green-disabled;
    border: 1px solid $mc-green-disabled;
  }
}

.btn-base.light-blue-btn,
.btn-base-lg.light-blue-btn-lg,
.btn-base-xl.light-blue-btn-xl {
  //background-color: $mc-blue-04;
  //border: 1px solid $mc-blue-04;
  border: 1px solid #D9D9D9;
  background-color: #fff;
  color: #1A2A35;

  &:hover {
    //background-color: $mc-light-blue-hover;
    background-color: #f7f7f7;
    //border: 1px solid $mc-light-blue-hover;
  }

  &:disabled {
    //background-color: $mc-light-blue-disabled;
    //border: 1px solid $mc-light-blue-disabled;
  }
}

.btn-base.orange-btn,
.btn-base-lg.orange-btn-lg,
.btn-base-xl.orange-btn-xl {
  background-color: $mc-orange-01;
  border: 1px solid $mc-orange-01;

  &:hover {
    background-color: $mc-orange-hover;
    border: 1px solid $mc-orange-hover;
  }

  &:disabled {
    background-color: $mc-orange-disabled;
    border: 1px solid $mc-orange-disabled;
  }
}

.btn-base.red-btn,
.btn-base-lg.red-btn-lg,
.btn-base-xl.red-btn-xl{
  background-color: $mc-red-01;
  border: 1px solid $mc-red-01;

  &:hover {
    background-color: $mc-red-hover;
    border: 1px solid $mc-red-hover;
  }

  &:disabled {
    background-color: $mc-red-disabled;
    border: 1px solid $mc-red-disabled;
  }
}

.btn-base.yellow-btn,
.btn-base-lg.yellow-btn-lg,
.btn-base-xl.yellow-btn-xl {
  color: #fff;
  background-color: #1A2A35;
  border: 1px solid #1A2A35;

  &:hover {
    //background-color: $mc-yellow-hover;
    background-color: #303f49;
    border: 1px solid #303f49;
  }

  &:disabled {
    //background-color: $mc-yellow-disabled;
    //border: 1px solid $mc-yellow-disabled;
    background-color: #fff;
    border: 1px solid #D9D9D9;
  }
}

.btn-base-lg.yellow-btn-lg.save {
  background-color: #1A2A35;
  color: #fff;
  border: 1px solid #1A2A35;
}

.btn-base.dark-blue-btn,
.btn-base-lg.dark-blue-btn-lg,
.btn-base-xl.dark-blue-btn-xl {
  //background-color: #1a2a35;
  border: 1px solid #D9D9D9;
  background-color: #fff;
  color: #1A2A35;

  &:hover {
    background-color: #f7f7f7;
    border: 1px solid #D9D9D9;
  }

  &:disabled {
    background-color: $mc-dark-blue-disabled;
    border: 1px solid $mc-dark-blue-disabled;
  }
}

.button_actions, .shift_button_actions {
  margin-left: 1rem !important;
}

.btn-nowrap {
  white-space: nowrap;
}

.edit-calendar-buttons {
  display: flex;
  justify-content: space-between;
  padding: 0 0.5rem;
  gap: 1rem;
}

.edit-calendar-controls {
  display: flex;
  flex-wrap: wrap;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    align-items: stretch;
  }
}

.edit-calendar-actions {
  display: flex;
  align-items: flex-start;
}