@import "../../core/colours";

.calendar-compliance-tiles {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(19rem, 1fr));
  gap: 1.5rem;
  background-color: $c8;
  padding: 1.5rem;

  @media (min-width: 1024px) {
    grid-template-columns: repeat(auto-fit, minmax(23rem, 1fr));
  }
}

.calendar-compliance-tile {
  border-radius: 0.5rem;
  background-color: #fff;
  padding: 1.5rem;

  h2 {
    font-weight: 500;
    font-size: 1rem;
    margin: 0;
    padding: 0;

    a {
      text-decoration: underline !important;
    }
  }

  a {
    color: $c1-content;
  }
}

.calendar-compliance-tile-heading {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 1rem;
  align-items: center;
}

.calendar-compliance-tile-heading-controls {
  text-align: right;
  display: flex;
  align-items: flex-start;

  svg {
    position: relative;
    top: -1px;
  }
}

.calendar-compliance-tile-lines {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.calendar-compliance-tile-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.calendar-compliance-tile-line-block {
  display: flex;
  gap: 0.6rem;
  align-items: center;
}

.calendar-compliance-tile-line-icon {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 0.25rem;
  background-color: #f4f5f7;
  display: flex;
  justify-content: center;
  align-items: center;

  &.compliant {
    background-color: #edf9f0;
    color: #2a8a41;
  }

  &.partialcompliant {
    background-color: #fffbf2;
    color: #e0a309;
  }

  &.notcompliant {
    background-color: #feefef;
    color: #c70a0a;
  }
}

.calendar-compliance-tile-line-today {
  background-color: #f4f7f8;
  color: #2a5a7b;
  font-size: 0.9rem;
  padding: 0 0.25rem;
  border-radius: 0.15rem;
}
