
.page {
  min-height: 100vh;
  margin: 0 !important;
}

.main-content-overlay {
  z-index: 1;
  background-color: #fff !important;
}
.z-index-fix {
  //position: relative;
  z-index: 1;
}
.main-layout {
  position: relative;
  z-index: 2;
}

.center-items {
  display: grid;
  justify-content: center;
  align-items: center;
}

.background-stars {
  //background-image: url(https://medicare-ems.co.uk/wp-content/uploads/2022/03/Asset-4.svg);
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.edit-calendar-entry {
  background-color: #F4F5F7;
}

.edit-calendar-entry .background-stars {
  //background-color: #fff;
  //margin: 1.5rem 3rem;
  //border-radius: 1rem;
  //padding: 1rem;
}

.edit-calendar-entry .row {
  flex-wrap: wrap;
}

.edit-calendar-calendar-header {
  margin: 1em 3rem;
  background-color: #fff;
  border-radius: 1rem;
  padding: 0.5rem;

  @media only screen and (max-width: 600px) {
    margin: 0;
  }
}

.edit-calendar-event-details {
  margin: 1em 3rem;
  background-color: #fff;
  border-radius: 1rem;
  padding: 0.5rem;

  @media only screen and (max-width: 600px) {
    margin: 0;
  }
}

.edit-calendar-staff-management {
  margin: 1rem 3rem;
  background-color: #fff;
  border-radius: 1rem;
  padding: 0.5rem;

  @media only screen and (max-width: 600px) {
    margin: 0;
  }
}

.edit-calendar-admin-actions {
  margin: 0rem 2rem;
  padding: 0.5rem;
}

.edit-calendar-shift-actions {
  margin: 0rem 2rem;
  padding: 0.5rem;

  @media only screen and (max-width: 600px) {
    margin: 0;
  }
}

.background-chevrons {
  background-image: url(https://medicare-ems.co.uk/wp-content/uploads/2022/03/Arrows-Grey.svg);
}

.background-clear {
  background-color: rgb(235, 235, 235);
}

.full-page-bg {
  min-height: 100vh;
}

.background-stars,
.background-arrows,
.background-chevrons {
  background-repeat: repeat!important;
}

.background-overlay {
  //background-color: rgba(220, 220, 220, 0.4);
  //background-color: #fff;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.col {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.col .col_override {
  padding-left: 0rem !important;
  padding-right: 0rem !important;
}

.col h6 {
  margin-bottom: 1rem !important;
}

.col label{
  padding-right: 1rem;
}

.col-sm-6 {
  padding: 0 !important;
}

.calendar-group-card .mc-form-row {
  margin:0 !important;
}

.calendar-group-card .mc-form-row .row {
  //margin-left: 0;
}

.mt-3 {
  margin-top: 0 !important;
}

.mr-3 {
  margin-right: 0.5rem !important;
}

.loading-overlay {
  z-index: 10000 !important;
}

.entry_settings {
  background-color: #FAFAFA;
  padding: 1rem;
  border-radius: 0.5rem;
  margin: 0rem 1rem 1.5rem 1rem;
}

h6.group_titles {
  background: #fff;
  padding: 0.5rem 1rem 0.5rem 1rem;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  margin-bottom: 0;
  margin-top: 0.5rem;
}

.col.group_list {
  background: #fff;
  padding: 1rem;
  border-radius: 0.5rem;
  margin-right: 1.5rem;
}

.toggle_title {
  font-size: 1rem;
}

.row-modifier .actions {
  margin-left: 0rem;
}

h5.text-center {
  font-size: 1.1rem;
}

