@import "../../core/colours";

.fc-daygrid-event {
  border: none !important;
  border-radius: 0 !important;
  background-color: #fff !important;
}

.fc-daygrid-day-number {
  color: #1a2a35;
}

.fc-theme-standard th {
  background-color: #f3f4f5;
}

.fc-daygrid-day.fc-day-today {
  background-color: #ffffff !important;
}

.fc-day-today {
  .fc-daygrid-day-number {
    color: white;
    background-color: #2A5A7B;
    border-radius: 1rem;
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.fc-daygrid-event {
  white-space: wrap !important;
}

.fc-highlight {
  background-color: #fff !important;
}

.custom-event-tab {
  background-color: #eef2fa;
  color: #2E5AAC;
  border: 2px solid #2E5AAC;
  border-radius: 5px;
  padding: 0.25rem 0.5rem;
  cursor: pointer;
  width: 100%;

  // **** GRS CALENDAR ****
  &.historic {
    border-color: #2A8A41;
    background-color: #edf9f0;
    color: #2A8A41;
  }

  &.historic:hover {
    border-color: #7fb98d;
  }

  &.briefing {
    border-color: #2A8A41;
    background-color: #edf9f0;
    color: #2A8A41;
  }

  &.briefing:hover {
    border-color: #7fb98d;
  }

  &.unknown {
    border-color: #876D17;
    background-color: #fffbf2;
    color: #876D17;
  }

  &.unknown:hover {
    border-color: #b7a774;
  }

  &.unassigned {
    border-color: #C70A0A;
    background-color: #feefef;
    color: #C70A0A;
  }

  &.unassigned:hover {
    border-color: #dd6c6c;
  }

  // **** AVAILABLE SHIFTS ****
  &.future {
    border-color: #2A8A41;
    background-color: #edf9f0;
    color: #2A8A41;
  }

  &.future:hover {
    border-color: #7fb98d;
  }

  &.no-space {
    border-color: #2A8A41;
    background-color: #edf9f0;
    color: #2A8A41;
  }

  &.no-space:hover {
    border-color: #7fb98d;
  }

  &.space-available {
    border-color: #876D17;
    background-color: #fffbf2;
    color: #876D17;
  }

  &.space-available:hover {
    border-color: #b7a774;
  }

  // **** STATE OVERRIDES ****
  &:hover {
    border-color: $c2;
  }
}