.d-grid {
  display: grid;
  align-items: center;
  justify-content: center;
}

.width-250px {
  width: 250px !important;
}

.col-padding {
  padding: 0 15px;
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-default {
  cursor: default !important;
}

.z-50 {
  z-index: 50 !important;
}
